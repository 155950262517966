<template>
    <b-card>
        <b-tabs vertical>
            <b-tab v-for="(report, date) in reports" :title="date" :key="date">
                <b-tabs>
                    <b-tab v-for="(website, websiteId) in report" :key="websiteId" :title="website.name">
                        <template #title>
                            {{ website.name }}
                            <b-badge variant="danger" class="font-small-1" v-if="websiteHasErrors(website)">*</b-badge>
                        </template>
                        <b-card no-body>
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'product-'+website.id">Product <b-badge v-if="outputHasErrors(website.product.output)" variant="danger">has error</b-badge></b-button>
                            </b-card-header>
                            <b-collapse :id="'product-'+website.id" visible :accordion="'a' + website.id"
                                        role="tabpanel">
                                <b-card-body>
                                    <a :href="website.product.link" target="_blank">{{ website.product.link }}</a>
                                    <pre>
                                        {{ website.product.output }}
                                    </pre>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body>
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'category-'+website.id">Category <b-badge v-if="outputHasErrors(website.category.output)" variant="danger">has error</b-badge></b-button>
                            </b-card-header>
                            <b-collapse :id="'category-'+website.id" visible :accordion="'a' + website.id"
                                        role="tabpanel">
                                <b-card-body>
                                    <a :href="website.category.link" target="_blank">{{ website.category.link }}</a>
                                    <pre>
                                        {{ website.category.output }}
                                    </pre>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body>
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'category_links-'+website.id">Category Links <b-badge v-if="outputHasErrors(website.category_links.output)" variant="danger">has error</b-badge></b-button>
                            </b-card-header>
                            <b-collapse :id="'category_links-'+website.id" visible :accordion="'a' + website.id"
                                        role="tabpanel">
                                <b-card-body>
                                    <a :href="website.category_links.link"
                                       target="_blank">{{ website.category_links.link }}</a>
                                    <pre>
                                        {{ website.category_links.output }}
                                    </pre>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </b-tab>
                </b-tabs>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import {BTabs, BTab, BCard, BCollapse, BCardHeader, BButton, BCardBody, VBToggle, BBadge} from "bootstrap-vue";
export default {
    name: "XpathReports",
    components: {
        BTabs, BTab, BCard, BCollapse, BCardHeader, BButton, BCardBody, BBadge
    },
    directives: {
        'b-toggle': VBToggle
    },
    data() {
        return {
            reports: [],
            possibleErrorStrings: ['missing', 'Missing field data for', '+------+\n' + '| Link |\n' + '+------+', 'Showing 10 of 0', 'No job found']
        }
    },
    mounted() {
        this.fetchReports()
    },
    methods: {
        fetchReports() {
            this.$http.get('/support/xpath-reports')
                .then(response => {
                    this.reports = response.data
                })
        },
        websiteHasErrors(website) {
            return this.outputHasErrors(website.product.output) || this.outputHasErrors(website.category.output) || this.outputHasErrors(website.category_links.output)
        },
        outputHasErrors(output) {
            return this.possibleErrorStrings.some(string => output.includes(string));
        }
    }
}
</script>

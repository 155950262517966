<template>
    <b-form>
        <b-card>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Key name">
                        <b-form-input v-model="lazyValue.key_name"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Type">
                        <v-select v-model="lazyValue.type" :options="typeOptions" :reduce="v => v.value"
                                  label="label"></v-select>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group label="Expression">
                        <b-form-input v-model="lazyValue.expression"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Get attribute">
                        <b-form-input v-model="lazyValue.get_attr"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Parent">
                        <v-select :options="xpaths" :reduce="v => v.id" v-model="lazyValue.parent" label="key_name">
                            <template #selected-option="{ key_name, type }">
                                <div style="display: flex; align-items: baseline">
                                    ({{ type }}) {{ key_name }}
                                </div>
                            </template>
                            <template #option="option">
                                ({{ option.type }}) {{ option.key_name }}
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Prepend">
                        <b-form-input v-model="lazyValue.prepend"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Append">
                        <b-form-input v-model="lazyValue.append"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group label="Pipes">
                        <v-select v-model="lazyValue.pipes" multiple :options="pipes"></v-select>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <b-row>
                <b-col md="3">
                    <b-form-checkbox v-model="lazyValue.get_first" switch :disabled="lazyValue.get_last">Get first?
                    </b-form-checkbox>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="lazyValue.get_last" switch :disabled="lazyValue.get_first">Get last?
                    </b-form-checkbox>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="lazyValue.get_text" switch :disabled="lazyValue.get_html">Get text?
                    </b-form-checkbox>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="lazyValue.get_html" switch :disabled="lazyValue.get_text">Get html?
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <div class="d-flex flex-row">
            <b-button variant="success" class="mr-2" @click="save">Save</b-button>
            <b-button variant="secondary" @click="$emit('close')">Cancel</b-button>
        </div>
    </b-form>
</template>

<script>
import {BForm, BRow, BCol, BFormGroup, BFormInput, BCard, BFormCheckbox, BButton} from "bootstrap-vue";
import vSelect from "vue-select"
import {mapActions} from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BForm, BRow, BCol, BFormGroup, BFormInput, BCard, BFormCheckbox, BButton,
        vSelect,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        xpaths: {
            type: Array,
            required: false,
            default: () => []
        },
        websiteId: {
            type: String,
            required: true
        }
    },
    created() {
        this.lazyValue = window._.clone(this.value)
        if (!this.pipes.length) {
            this.fetchPipes()
        }
    },
    data() {
        return {
            lazyValue: {},
            typeOptions: [
                {value: 'product', label: 'Product'},
                {value: 'category', label: 'Category'},
                {value: 'category_links', label: 'Category links'},
            ]
        }
    },
    name: "XPathForm",
    computed: {
        pipes() {
            return this.$store.state.support.pipes
        }
    },
    methods: {
        ...mapActions('websites', ['saveXpath']),
        ...mapActions('support', ['fetchPipes']),
        save() {
            this.saveXpath({
                website: this.websiteId,
                xpath: this.lazyValue
            }).then(() => {
                this.$emit('close')
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Success',
                        icon: 'CheckIcon',
                        text: 'Xpath saved',
                        variant: 'success'
                    },
                })
            }).catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'BellIcon',
                        text: error.message,
                        variant: 'danger'
                    },
                })
            })
        }
    },
    watch: {
        lazyValue(newValue) {
            this.$emit('input', newValue)
        }
    }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import websites from './admin/websites'
import support from './admin/support'
import categories from './admin/categories'
import attributes from './admin/attributes'
import totem from './admin/totem'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        websites,
        support,
        categories,
        attributes,
        appConfig,
        verticalMenu,
        totem,
        app
    },
    strict: process.env.DEV,
})

<template>
    <b-card>
        <div class="d-flex justify-content-end mb-2">
            <b-overlay :show="saveLoading" rounded
                       spinner-small
                       spinner-variant="primary"
                       class="d-inline-block"
            >
                <b-button variant="primary" @click="saveMappings" :disabled="saveLoading">Save mappings</b-button>
            </b-overlay>
        </div>
        <b-progress :max="totalCategories" show-progress  class="my-1" >
            <b-progress-bar :value="offset" :label="`${((offset / totalCategories) * 100).toFixed(2)}%`"></b-progress-bar>
        </b-progress>
        <b-table :items="constants" :fields="fields" :busy="tableLoading" fixed>
            <template #cell(possibilities)="data">
                <b-button size="sm" variant="primary" @click="data.item.forSave = data.item.possibilities" :disabled="data.item.forSave === undefined ? false : data.item.forSave.length === data.item.possibilities.length">Select all</b-button>
                <vue-select :options="data.item.possibilities" multiple
                            :key="data.item.value"
                            @input="v => data.item.forSave = v"></vue-select>
            </template>
        </b-table>
        <div class="d-flex justify-content-center">
            <b-button @click="offset-=limit" :disabled="tableLoading || offset === 0" class="mr-1" variant="secondary">
                Previous
            </b-button>
            <b-button @click="offset+=limit" :disabled="tableLoading" variant="secondary">Next</b-button>
        </div>
    </b-card>
</template>

<script>
import {BCard, BTable, BButton, BOverlay, BProgress, BProgressBar} from "bootstrap-vue";
import VueSelect from 'vue-select'
import _ from 'lodash'
import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        BCard,
        BTable,
        BButton,
        VueSelect,
        BOverlay,
        BProgress,
        BProgressBar
    },
    name: "ConstantsMapping",
    data() {
        return {
            constants: [],
            fields: [
                {key: 'label', label: 'Constant'},
                {key: 'possibilities', label: 'Possible categories'}
            ],
            tableLoading: false,
            offset: 0,
            limit: 10,
            saveLoading: false,
            totalCategories: 0
        }
    },
    created() {
        this.fetchConstants()
    },
    methods: {
        fetchConstants() {
            this.tableLoading = true
            this.$http.get('/categories/constants', {
                params: {
                    offset: this.offset,
                    limit: this.limit
                }
            })
                .then(response => {
                    this.constants = response.data.items
                    this.totalCategories = response.data.total
                    if(!this.constants.length){
                        this.offset+=this.limit
                    }
                })
                .finally(() => {
                    this.tableLoading = false
                })
        },
        saveMappings() {
            let items = [];

            _.forEach(this.constants, constant => {
                _.forEach(constant.forSave, categoryName => {
                    items.push({
                        name: categoryName,
                        mapping: constant.value
                    })
                })
            })
            if (items.length < 1) {
                return
            }
            this.saveLoading = true
            axios.post('/categories/mappings', {
                mappings: items
            }).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Success',
                        icon: 'CheckIcon',
                        text: 'Mappings saved',
                        variant: 'success'
                    },
                })
                this.fetchConstants()
            }).catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'BellIcon',
                        text: error.message,
                        variant: 'danger'
                    },
                })
            }).finally(() => {
                this.saveLoading = false
            })
        }
    },
    watch: {
        offset() {
            this.fetchConstants()
        }
    }
}
</script>

<template>
    <div>
        <b-card>
            <div class="mb-3">
                <b-button :to="{name: 'constants.mapping'}" variant="primary">Constants possible mappings</b-button>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <b-form-checkbox v-model="onlyUnmapped">Show only unmapped</b-form-checkbox>
                <div>
                    <b-button @click="saveAll" class="mr-1" variant="success" :disabled="saveLoading">
                        <b-spinner small v-if="saveLoading"></b-spinner>
                        Save all ({{ itemsForSave.length }})
                    </b-button>
                    <b-button @click="itemsForSave = []" variant="danger">Clear unsaved</b-button>
                </div>
            </div>
            <div>
                <b-form-input type="search" placeholder="Search... (on enter)"
                              autofocus class="mb-2"
                              v-model="searchQuery"
                              @keydown.enter="searchByName"></b-form-input>
            </div>
            <b-table :items="categories.data" :fields="fields" :busy="tableLoading" fixed>
                <template #cell(mapping)="data">
                    <v-select v-model="data.item.mapping" :options="mappings" :reduce="v => v.value"
                              @input="addToCollection(data.item)"
                              label="label"></v-select>
                </template>
            </b-table>
            <div class="d-flex justify-content-center">
                <b-button @click="page--" :disabled="tableLoading || page === 1" class="mr-1" variant="secondary">
                    Previous
                </b-button>
                <b-button @click="page++" :disabled="tableLoading" variant="secondary">Next</b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
import {BCard, BTable, BFormCheckbox, BButton, BSpinner, BFormInput} from "bootstrap-vue";
import vSelect from 'vue-select'
import {mapActions} from "vuex";
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    name: "CategoriesMapping",
    components: {
        BCard, BTable, BFormCheckbox, BButton, BSpinner,
        vSelect, BFormInput,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent
    },
    data() {
        return {
            fields: [
                {key: 'name', label: 'Name'},
                {key: 'mapping', label: 'Mapping'},
            ],
            onlyUnmapped: true,
            perPage: 20,
            page: 1,
            tableLoading: false,
            itemsForSave: [],
            saveLoading: false,
            searchQuery: null
        }
    },
    created() {
        this.tableLoading = true
        if (!this.mappings) {
            this.fetchMappings()
        }
        this.fetchCategories({
            perPage: this.perPage,
            page: this.page,
            onlyUnmapped: this.onlyUnmapped
        }).finally(() => {
            this.tableLoading = false
        })
    },
    methods: {
        ...mapActions('categories', ['fetchMappings', 'fetchCategories']),
        saveAll() {
            this.saveLoading = true
            axios.post('/categories/mappings', {
                mappings: this.itemsForSave
            }).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Success',
                        icon: 'CheckIcon',
                        text: 'Mappings saved',
                        variant: 'success'
                    },
                })
                this.itemsForSave = []
            }).catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'BellIcon',
                        text: error.message,
                        variant: 'danger'
                    },
                })
            }).finally(() => {
                this.saveLoading = false
            })
        },
        addToCollection(item) {
            if (item.mapping) {
                this.itemsForSave.push(item)
                return;
            }

            let itemIndex = window._.findIndex(this.itemsForSave, {name: item.name});
            if (itemIndex > -1) {
                this.itemsForSave.splice(itemIndex, 1)
            }
        },
        searchByName() {
            this.tableLoading = true
            this.fetchCategories({
                query: this.searchQuery,
                onlyUnmapped: this.onlyUnmapped
            }).finally(() => {
                this.tableLoading = false
            })
        }
    },
    computed: {
        mappings() {
            return this.$store.state.categories.mappings.categories
        },
        categories() {
            return this.$store.state.categories.categoriesPaginated
        }
    },
    watch: {
        page(newValue) {
            this.tableLoading = true
            this.fetchCategories({
                perPage: this.perPage,
                page: newValue,
                onlyUnmapped: this.onlyUnmapped,
                query: this.searchQuery
            }).finally(() => {
                this.tableLoading = false
            })
        },
        onlyUnmapped(newValue) {
            this.tableLoading = true
            this.fetchCategories({
                perPage: this.perPage,
                page: this.page,
                onlyUnmapped: newValue,
                query: this.searchQuery
            }).finally(() => {
                this.tableLoading = false
            })
        }
    }
}
</script>

export default [
    {
        path: '/websites',
        name: 'websites',
        component: () => import('@/views/admin/Websites/WebsitesList'),
    },
    {
        path: '/websites/:id',
        name: 'website',
        component: () => import('@/views/admin/Websites/WebsiteForm'),
    },
    {
        path: '/websites/:id/xpaths',
        name: 'xpaths',
        component: () => import('@/views/admin/Websites/WebsiteXpaths'),
    },
    {
        path: '/categories/mapping',
        name: 'categories.mapping',
        component: () => import('@/views/admin/Categories/CategoriesMapping'),
    },
    {
        path: '/constants/mapping',
        name: 'constants.mapping',
        component: () => import('@/views/admin/Categories/ConstantsMapping'),
    },
    {
        path: '/attributes',
        name: 'attributes',
        component: () => import('@/views/admin/Attributes/AttributesList'),
    },
    {
        path: '/support/xpath-reports',
        name: 'xpath.reports',
        component: () => import('@/views/admin/Support/XpathReports')
    },
    {
        path: '/totem',
        name: 'totem.dashboard',
        component: () => import('@/views/admin/Totem/TotemDashboard')
    },
    {
        path: '/totem/edit/:id',
        name: 'totem.edit.task',
        component: () => import('@/views/admin/Totem/TotemTaskForm')
    },
    {
        path: '/totem/create',
        name: 'totem.create.task',
        component: () => import('@/views/admin/Totem/TotemTaskForm')
    },
    {
        path: '/totem/:id',
        name: 'totem.task',
        component: () => import('@/views/admin/Totem/TotemTask')
    },
]

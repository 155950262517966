<template>
    <div>
        <div class="d-flex flex-wrap websites-list mb-2">
            <b-button size="sm" v-for="website in _.filter(websites, item => !_.isNull(item.downloader_class))"
                      :key="website.id"
                      @click="activeWebsite = website.id"
                      :variant="website.id === activeWebsite ? 'primary': 'secondary'"
            >
                {{ website.name }}
            </b-button>
        </div>
        <b-card v-if="activeWebsite">
            <div class="d-flex justify-content-between mb-2">
                <b-form-checkbox v-model="onlyNotMapped" class="mb-1">Show only not mapped</b-form-checkbox>
                <div>
                    <b-button @click="saveAll" class="mr-1" variant="success" :disabled="saveLoading">
                        <b-spinner small v-if="saveLoading"></b-spinner>
                        Save all ({{ itemsForSave.length }})
                    </b-button>
                    <b-button @click="itemsForSave = []" variant="danger">Clear unsaved</b-button>
                </div>
            </div>
            <b-table :fields="fields" :items="attributes.data" :busy="tableLoading" fixed>
                <template #cell(code)="data">
                    <v-select v-model="data.item.code" :options="mappings" :reduce="v => v.value"
                              label="text"></v-select>
                </template>
                <template #cell(type)="data">
                    <v-select v-model="data.item.type" :options="typeOptions" :reduce="v => v.value"
                              @input="addToCollection(data.item)"
                              label="label"></v-select>
                </template>
            </b-table>
            <div class="d-flex justify-content-center">
                <b-button @click="page--" :disabled="tableLoading || page === 1" class="mr-1" variant="secondary">
                    Previous
                </b-button>
                <b-button @click="page++" :disabled="tableLoading" variant="secondary">Next</b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
import {BTable, BButton, BCard, BFormCheckbox, BSpinner} from "bootstrap-vue";
import vSelect from 'vue-select'
import {mapActions} from "vuex";
import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "AttributesList",
    components: {
        BTable, BButton, BCard, BFormCheckbox, BSpinner,
        vSelect
    },
    data() {
        return {
            fields: [
                {key: 'attribute', label: 'Attribute'},
                {key: 'code', label: 'Code'},
                {key: 'type', label: 'Type'},
            ],
            typeOptions: [
                {value: 'ATI', label: 'As it is'},
                {value: 'BOOL', label: 'Boolean'},
                {value: 'INT', label: 'Integer'},
                {value: 'DOUBLE', label: 'Double/Float'},
            ],
            page: 1,
            perPage: 20,
            tableLoading: false,
            onlyNotMapped: true,
            activeWebsite: null,
            websites: [],
            itemsForSave: [],
            saveLoading: false
        }
    },
    computed: {
        attributes() {
            return this.$store.state.attributes.attributesPaginated
        },
        mappings() {
            return this.$store.state.attributes.mappings
        }
    },
    created() {
        this.tableLoading = true
        this.fetchMappings()
        if (!this.websites.length) {
            this.fetch({
                perPage: 1000
            }).then(response => {
                this.websites = response.data.data
            })
        }
    },
    methods: {
        ...mapActions('attributes', ['fetchAttributes', 'fetchMappings']),
        ...mapActions('websites', ['fetch']),
        saveAll() {
            this.saveLoading = true
            axios.post('/attributes/mappings', {
                mappings: this.itemsForSave
            }).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Success',
                        icon: 'CheckIcon',
                        text: 'Mappings saved',
                        variant: 'success'
                    },
                })
                this.itemsForSave = []
            }).catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'BellIcon',
                        text: error.message,
                        variant: 'danger'
                    },
                })
            }).finally(() => {
                this.saveLoading = false
            })
        },
        addToCollection(item) {
            if (item.code) {
                let itemIndex = window._.findIndex(this.itemsForSave, {attribute: item.attribute});
                if (itemIndex === -1) {
                    this.itemsForSave.push(item)
                    return;
                }
                this.itemsForSave[itemIndex] = item;
                return;
            }

            let itemIndex = window._.findIndex(this.itemsForSave, {attribute: item.attribute});
            if (itemIndex > -1) {
                this.itemsForSave.splice(itemIndex, 1)
            }
        },
    },
    watch: {
        activeWebsite(newValue) {
            this.tableLoading = true
            this.fetchAttributes({
                perPage: this.perPage,
                page: this.page,
                onlyNotMapped: this.onlyNotMapped,
                website_id: newValue
            }).finally(() => {
                this.tableLoading = false;
            })
        },
        onlyNotMapped(newValue) {
            this.tableLoading = true
            this.fetchAttributes({
                perPage: this.perPage,
                page: 1,
                onlyNotMapped: newValue,
                website_id: this.activeWebsite
            }).finally(() => {
                this.tableLoading = false;
            })
        },
        page(newValue) {
            this.tableLoading = true
            this.fetchAttributes({
                perPage: this.perPage,
                page: newValue,
                onlyNotMapped: this.onlyNotMapped,
                website_id: this.activeWebsite
            }).finally(() => {
                this.tableLoading = false;
            })
        }
    }
}
</script>
<style scoped>
.websites-list {
    display: grid;
    grid-gap: 5px;
}
</style>

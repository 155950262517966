<template>
  <b-form>
    <b-row class="mb-2">
      <b-col>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <b-card>
          <b-row>
            <b-col md="6">
              <b-form-group label="Name" class="required">
                <b-form-input v-model="website.name"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Domain">
                <b-form-input v-model="website.domain"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Category links url">
                <b-form-input v-model="website.category_links_url"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Downloader class">
                <v-select v-model="website.downloader_class" :options="downloaderClasses"></v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Rate limiter class">
                <v-select v-model="website.rate_limiter" :options="rateLimiters"></v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Currency">
                <v-select v-model="website.currencyCode" :options="currencies"></v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Delivery to">
                <v-select v-model="website.countries" :options="countries" label="name"></v-select>
              </b-form-group>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
      <b-col md="3">
        <b-card>
          <b-form-group>
            <b-form-checkbox
                v-model="website.is_active"
                switch
                inline
            >
              Is Active?
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
                v-model="website.using_proxy"
                switch
                inline
            >
              Using proxy?
            </b-form-checkbox>
          </b-form-group>
        </b-card>
        <b-button variant="success" class="btn-block" @click="storeWebsite" :disabled="saveLoading">Save changes
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {mapActions} from "vuex";
import {
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BCol,
  BFormCheckbox,
  BCard,
  BButton,
  BBreadcrumb
} from "bootstrap-vue";
import vSelect from "vue-select"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  name: "WebsiteForm",
  components: {
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BCol,
    BFormCheckbox,
    BCard,
    BButton,
    vSelect,
    BBreadcrumb,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  methods: {
    ...mapActions('websites', ['get', 'saveWebsite']),
    ...mapActions('support', ['fetchDownloaders', 'fetchRateLimiters', 'fetchCountries']),
    storeWebsite() {
      this.saveLoading = true
      this.saveWebsite(this.website)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                text: 'Website saved',
                variant: 'success'
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: error.message,
                variant: 'danger'
              },
            })
          })
          .finally(() => {
            this.saveLoading = false;
          })
    }
  },
  data() {
    return {
      website: {},
      breadcrumbs: [
        {text: 'Dashboard', to: '/'},
        {text: 'Websites', to: '/websites'}
      ],
      saveLoading: false
    }
  },
  created() {
    this.get(this.$route.params.id).then(response => {
      this.website = response.data;
      this.breadcrumbs.push({
        text: this.website.name,
        active: true
      })
      this.breadcrumbs.push({
        text: 'xPaths',
        to: '/websites/' + this.website.id + '/xpaths',
        active: false
      })
    })
    this.fetchDownloaders()
    this.fetchRateLimiters()
    this.fetchCountries()
  },
  computed: {
    downloaderClasses() {
      return this.$store.state.support.downloaders;
    },
    rateLimiters() {
      return this.$store.state.support.rate_limiters;
    },
    currencies() {
      return this.$store.state.support.currencies
    },
    countries() {
      return this.$store.state.support.countries
    }
  }
}
</script>

import axios from '@axios'
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        tasks: []
    },
    mutations: {
        SET_TASKS: (state, data) => {
            state.tasks = data;
        },
        ADD_TASK: (state, task) => {
            let existingIndex = _.findIndex(state.tasks, {id: task.id})
            if (existingIndex === -1) {
                state.tasks.push(task)
            } else {
                state.tasks[existingIndex] = task
            }
        },
        DELETE_TASK: (state, id) => {
            let findIndex = _.findIndex(state.tasks, {id: id});
            if (findIndex > -1) {
                state.tasks.splice(findIndex, 1)
            }
        },
        ACTIVATE_TASK: (state, id) => {
            let findIndex = _.findIndex(state.tasks, {id: id});
            if (findIndex > -1) {
                state.tasks[findIndex].activated = 1
            }
        },
        DEACTIVATE_TASK: (state, id) => {
            let findIndex = _.findIndex(state.tasks, {id: id});
            if (findIndex > -1) {
                state.tasks[findIndex].activated = 0
            }
        }
    },
    actions: {
        fetchTotemTasks(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/totem')
                    .then(response => {
                        ctx.commit('SET_TASKS', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        fetchTask(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.get('/totem/' + id)
                    .then(response => {
                        ctx.commit('ADD_TASK', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        destroyTask(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/totem/' + id)
                    .then(response => {
                        ctx.commit('DELETE_TASK', id);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        activateTask(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.post('/totem/activate/' + id)
                    .then(response => {
                        ctx.commit('ACTIVATE_TASK', id);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        deactivateTask(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.post('/totem/deactivate/' + id)
                    .then(response => {
                        ctx.commit('DEACTIVATE_TASK', id);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        executeTask(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.post('/totem/execute/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        }
    }
}

import axios from '@axios'

export default {
    namespaced: true,
    state: {
        downloaders: [],
        rate_limiters: [],
        currencies: [
            'BGN',
            'USD',
            'EUR'
        ],
        countries: [],
        pipes: []
    },
    mutations: {
        SET_DOWNLOADERS: (state, data) => {
            state.downloaders = data;
        },
        SET_RATE_LIMITERS: (state, data) => {
            state.rate_limiters = data;
        },
        SET_COUNTRIES: (state, data) => {
            state.countries = data
        },
        SET_PIPES: (state, data) => {
            state.pipes = data
        }
    },
    actions: {
        fetchDownloaders(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/support/downloaders')
                    .then(response => {
                        ctx.commit('SET_DOWNLOADERS', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        fetchRateLimiters(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/support/rate-limiters')
                    .then(response => {
                        ctx.commit('SET_RATE_LIMITERS', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        fetchCountries(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/support/countries')
                    .then(response => {
                        ctx.commit('SET_COUNTRIES', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        fetchPipes(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/support/pipelines')
                    .then(response => {
                        ctx.commit('SET_PIPES', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        }
    }
}

<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-card>
      <b-button variant="primary" @click="editingXpath = {}">Create xpath</b-button>
      <b-tabs>
        <b-tab v-for="(type, index) in xpathTypes" :key="index" :title="type">
          <b-table :items="getItemsForType(type)" :fields="fields" responsive
                   @row-clicked="(item, index, event) => editingXpath = item">
            <template #cell(expression)="data">
              <code>{{ data.item.expression }}</code>
            </template>
            <template #cell(parent)="data">
              {{ _.get(_.find(xpaths, {id: data.item.parent}), 'key_name') }}
            </template>
            <template #cell(pipes)="data">
                            <span v-for="pipe in data.item.pipes" :key="pipe">
                                {{ _.last(pipe.split('\\')) }}
                            </span>
            </template>
            <template #cell(options)="data">
              <div class="d-flex flex-row flex-nowrap">
                <feather-icon
                    v-b-tooltip.hover.top="'Get first?'"
                    :icon="data.item.get_first ? 'CheckCircleIcon' : 'XCircleIcon'"
                    :class="{'text-success': data.item.get_first, 'text-danger': !data.item.get_first}"></feather-icon>
                <feather-icon
                    v-b-tooltip.hover.top="'Get last?'"
                    :icon="data.item.get_last ? 'CheckCircleIcon' : 'XCircleIcon'"
                    :class="{'text-success': data.item.get_last, 'text-danger': !data.item.get_last}"></feather-icon>
                <feather-icon
                    v-b-tooltip.hover.top="'From cents?'"
                    :icon="data.item.from_cents ? 'CheckCircleIcon' : 'XCircleIcon'"
                    :class="{'text-success': data.item.from_cents, 'text-danger': !data.item.from_cents}"></feather-icon>
                <feather-icon
                    v-b-tooltip.hover.top="'Get text?'"
                    :icon="data.item.get_text ? 'CheckCircleIcon' : 'XCircleIcon'"
                    :class="{'text-success': data.item.get_text, 'text-danger': !data.item.get_text}"></feather-icon>
                <feather-icon
                    v-b-tooltip.hover.top="'Get html?'"
                    :icon="data.item.get_html ? 'CheckCircleIcon' : 'XCircleIcon'"
                    :class="{'text-success': data.item.get_html, 'text-danger': !data.item.get_html}"></feather-icon>
              </div>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card>
      <b-form-group label="Link to test">
        <b-form-input v-model="test.link"></b-form-input>
      </b-form-group>
      <b-form-group label="Xpath">
        <v-select v-model="test.xpath" :options="_.filter(xpaths, x => !x.parent)" :reduce="v => v.id" label="type">
          <template #option="option">
            ({{ option.type }}) {{ option.key_name }}
          </template>
        </v-select>
      </b-form-group>
      <b-button variant="success" @click="testXpath" :disabled="!test.link || !test.xpath || testLoading">
        <b-spinner small v-if="testLoading"></b-spinner>
        Test
      </b-button>
      <pre v-if="xpathTestResult">
        {{ xpathTestResult }}
      </pre>
    </b-card>
    <b-sidebar v-model="xpathPanel" width="70%" right shadow @close="editingXpath = null" backdrop>
      <x-path-form :website-id="website.id" v-model="editingXpath" v-if="editingXpath !== null" class="px-3 py-2"
                   :xpaths="xpaths"
                   :key="_.get(editingXpath, 'id', 0)" @close="editingXpath = null"></x-path-form>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBreadcrumb,
  BTabs,
  BTab,
  BCard,
  BTable,
  VBTooltip,
  BSidebar,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner
} from "bootstrap-vue";
import {mapActions} from "vuex";
import XPathForm from "@/views/admin/Xpaths/XPathForm";
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  name: "WebsiteXpaths",
  components: {
    BRow,
    BCol,
    BBreadcrumb,
    BTabs,
    BTab,
    BCard,
    BTable,
    BSidebar,
    XPathForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BSpinner
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      website: {},
      breadcrumbs: [
        {text: 'Dashboard', to: '/'},
        {text: 'Websites', to: '/websites'}
      ],
      xpaths: [],
      fields: [
        {key: 'expression', label: 'Expression'},
        {key: 'key_name', label: 'Key name'},
        {key: 'parent', label: 'Parent'},
        {key: 'get_attr', label: 'Attribute'},
        {key: 'pipes', label: 'Pipes'},
        {key: 'append', label: 'Append'},
        {key: 'prepend', label: 'Prepend'},
        {key: 'options', label: 'Options'}
      ],
      xpathPanel: false,
      editingXpath: null,
      test: {
        link: null,
        xpath: null
      },
      xpathTestResult: null,
      testLoading: false
    }
  },
  created() {
    this.get(this.$route.params.id).then(response => {
      this.website = response.data;
      this.breadcrumbs.push({
        text: this.website.name,
        to: '/websites/' + this.website.id,
        active: false
      })
      this.breadcrumbs.push({
        text: 'xPaths',
        active: true
      })
    })
    this.getXpaths(this.$route.params.id)
        .then(response => {
          this.xpaths = response.data
        });
  },
  methods: {
    ...mapActions('websites', ['get', 'getXpaths']),
    getItemsForType(type) {
      return window._.filter(this.xpaths, xpath => {
        return xpath.type === type
      })
    },
    testXpath() {
      this.testLoading = true
      axios.get('/support/xpath-test', {params: this.test})
          .then(response => {
            this.xpathTestResult = response.data
          })
          .finally(() => {
            this.testLoading = false
          })
    }
  },
  computed: {
    xpathTypes() {
      return [...new Set(window._.map(this.xpaths, item => {
        return item.type
      }))];
    }
  },
  watch: {
    editingXpath(newValue) {
      this.xpathPanel = newValue !== null
    }
  }
}
</script>

import axios from '@axios'

export default {
    namespaced: true,
    state: {
        attributesPaginated: [],
        mappings: []
    },
    mutations: {
        SET_ATTRIBUTES: (state, data) => {
            state.attributesPaginated = data
        },
        SET_MAPPINGS: (state, data) => {
            state.mappings = data
        }
    },
    actions: {
        fetchAttributes(ctx, payload) {
            return new Promise((resolve, reject) => {
                return axios.get('/attributes', {
                    params: {
                        per_page: payload.perPage,
                        page: payload.page,
                        onlyNotMapped: payload.onlyNotMapped,
                        website_id: payload.website_id
                    }
                }).then(response => {
                    ctx.commit('SET_ATTRIBUTES', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        fetchMappings(ctx) {
            return new Promise((resolve, reject) => {
                return axios.get('/attributes/mappings').then(response => {
                    ctx.commit('SET_MAPPINGS', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

import axios from '@axios'

export default {
    namespaced: true,
    state: {
        categoriesPaginated: [],
        mappings: []
    },
    mutations: {
        SET_CATEGORIES: (state, data) => {
            state.categoriesPaginated = data
        },
        SET_MAPPINGS: (state, data) => {
            state.mappings = data
        }
    },
    actions: {
        fetchCategories(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.get('/categories', {
                    params: {
                        perPage: payload.perPage,
                        page: payload.page,
                        onlyUnmapped: payload.onlyUnmapped,
                        query: payload.query
                    }
                })
                    .then(response => {
                        ctx.commit('SET_CATEGORIES', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        fetchMappings(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/categories/mappings')
                    .then(response => {
                        ctx.commit('SET_MAPPINGS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}

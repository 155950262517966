<template>
    <div>
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group>
                        <b-form-input type="search" v-model="searchQuery" placeholder="Search" clearable></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col md="2">
                    <b-form-checkbox v-model="activeOnly">Active only</b-form-checkbox>
                </b-col>
                <b-col md="2">
                    <b-form-checkbox v-model="proxyOnly">Proxy only</b-form-checkbox>
                </b-col>
            </b-row>
            <b-table :items="websites.data" responsive="sm" :fields="fields">
                <template #cell(actions)="data">
                    <router-link :to="{name: 'website', params: {id: data.item.id}}">
                        <b-button variant="success" size="sm">Edit</b-button>
                    </router-link>
                    <router-link :to="{name: 'xpaths', params: {id: data.item.id}}" v-if="data.item.downloader_class" class="ml-1">
                        <b-button variant="primary" size="sm">xPaths</b-button>
                    </router-link>
                </template>
                <template #cell(is_active)="data">
                    <span class="badge"
                          :class="{'bg-success text-white': data.item.is_active, 'bg-danger text-white': !data.item.is_active}">{{
                            data.item.is_active ? 'Active' : 'Not active'
                        }}</span>
                </template>
                <template #cell(using_proxy)="data">
                    <span class="badge"
                          :class="{'bg-success text-white': data.item.using_proxy, 'bg-danger text-white': !data.item.using_proxy}">{{
                            data.item.using_proxy ? 'Yes' : 'No'
                        }}</span>
                </template>
            </b-table>
            <b-pagination
                v-model="page"
                :total-rows="websites.total"
                :per-page="perPage"
                align="center"
                class="my-0"
            />
        </b-card>
    </div>
</template>

<script>
import {
    BTable,
    BCard,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol
} from "bootstrap-vue";
import {mapActions} from "vuex"

export default {
    name: "WebsitesList",
    components: {
        BTable, BCard, BButton, BPagination, BFormGroup, BFormInput, BFormCheckbox,
        BRow, BCol
    },
    computed: {
        websites() {
            return this.$store.state.websites.websitesPaginated;
        }
    },
    data() {
        return {
            perPage: 10,
            page: 1,
            searchQuery: null,
            activeOnly: false,
            proxyOnly: false,
            fields: [
                {key: 'name', label: 'Name'},
                {key: 'domain', label: 'Domain'},
                {key: 'is_active', label: 'Active'},
                {key: 'using_proxy', label: 'Using Proxy'},
                {key: 'downloader_class', label: 'Downloader'},
                {key: 'actions', label: 'Actions'}
            ]
        }
    },
    created() {
        this.fetch({
            perPage: this.perPage,
            page: this.page
        })
    },
    methods: {
        ...mapActions('websites', ['fetch'])
    },
    watch: {
        page(newValue) {
            this.fetch({
                page: newValue,
                perPage: this.perPage,
                query: this.searchQuery,
                activeOnly: this.activeOnly,
                proxyOnly: this.proxyOnly
            })
        },
        searchQuery: window._.debounce(function (newValue) {
            this.fetch({
                page: 1,
                perPage: this.perPage,
                query: newValue,
                activeOnly: this.activeOnly,
                proxyOnly: this.proxyOnly
            })
        }, 300),
        activeOnly(newValue) {
            this.fetch({
                page: 1,
                perPage: this.perPage,
                query: this.searchQuery,
                activeOnly: newValue,
                proxyOnly: this.proxyOnly
            })
        },
        proxyOnly(newValue) {
            this.fetch({
                page: 1,
                perPage: this.perPage,
                query: this.searchQuery,
                activeOnly: this.activeOnly,
                proxyOnly: newValue
            })
        }
    }
}
</script>

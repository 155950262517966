<template>
    <b-card>
        <b-card-header class="d-flex justify-content-between">
            <div class="flex-grow-1 mr-2"><b-form-input v-model="query" type="search" autofocus placeholder="Type to search command"></b-form-input></div>
            <div ><b-button variant="primary" :to="{name: 'totem.create.task'}">Create task</b-button></div>
        </b-card-header>
        <b-table :items="tasks" :fields="fields">
            <template #cell(description)="data">
                <router-link :to="{name: 'totem.task', params: {id: data.item.id}}">{{data.item.description}}</router-link>
            </template>
            <template #cell(status)="data">
                <feather-icon icon="CircleIcon" class="text-success" v-if="data.item.activated === 1"></feather-icon>
                <feather-icon icon="XCircleIcon" class="text-danger" v-if="data.item.activated === 0"></feather-icon>
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="primary" size="sm">
                    <template #button-content>
                        <feather-icon icon="SettingsIcon">{{data.item.id}}</feather-icon>
                    </template>
                    <b-dropdown-item :to="{name: 'totem.task', params: {id: data.item.id}}">View</b-dropdown-item>
                    <b-dropdown-item :to="{name: 'totem.edit.task', params: {id: data.item.id}}">Edit</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="executeTask(data.item.id)">Execute</b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import {mapActions} from "vuex";
import {BCard, BTable, BCardHeader, BFormInput, BButton, BDropdown, BDropdownItem, BDropdownDivider} from "bootstrap-vue";
import _ from 'lodash'
export default {
    name: "TotemDashboard",
    components: {
      BCard, BTable, BCardHeader, BFormInput, BButton,BDropdown, BDropdownItem, BDropdownDivider
    },
    data(){
      return {
          query: '',
          fields: [
              {key: 'status', label: 'status', sortable: true, filter: true},
              {key: 'description', label: 'Description'},
              {key: 'average_runtime', label: 'Average runtime', formatter: v => Math.round(v) / 1000 + ' sec', sortable: true},
              {key: 'last_ran_at', label: 'Last run'},
              {key: 'upcoming', label: 'Next run'},
              {key: 'actions', label: ''},
          ]
      }
    },
    computed: {
        tasks() {
            return _.filter(this.$store.state.totem.tasks, task => {
                return _.isEmpty(this.query) || task.description.toLowerCase().includes(this.query.toLowerCase())
            })
        }
    },
    mounted() {
        this.fetchTotemTasks()
    },
    methods: {
        ...mapActions('totem', ['fetchTotemTasks', 'destroyTask', 'executeTask']),
    }
}
</script>

<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>
                <router-link :to="{name: 'totem.dashboard'}">
                    <feather-icon icon="ArrowLeftIcon"></feather-icon>
                    Back
                </router-link>
            </b-breadcrumb-item>
        </b-breadcrumb>
        <b-card v-if="task">
            <b-card-header class="d-flex justify-content-between">
                <span class="font-large-1">Task details</span>
                <b-button variant="success" size="sm" v-if="task.activated === 1" @click="deactivateTask(task.id)">
                    Disable
                </b-button>
                <b-button variant="danger" size="sm" v-if="task.activated === 0" @click="activateTask(task.id)">Enable
                </b-button>
            </b-card-header>
            <b-list-group flush>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.description }}</span>
                    <span>Description</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.command }}</span>
                    <span>Command</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.parameters || 'N/A' }}</span>
                    <span>Parameters</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <div>
                        <p v-for="freq in task.frequencies" :key="freq.id">
                            {{ task.expression || task.interval }} ({{ freq.label }})
                        </p>
                    </div>
                    <span>Frequencies</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.timezone || 'N/A' }}</span>
                    <span>Timezone</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.created_at || 'N/A' }}</span>
                    <span>Created at</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.updated_at || 'N/A' }}</span>
                    <span>Updated at</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.notification_email_address || 'N/A' }}</span>
                    <span>Email notification</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.notification_phone_number || 'N/A' }}</span>
                    <span>SMS notification</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.notification_slack_webhook || 'N/A' }}</span>
                    <span>Slack notification</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ Math.round(task.average_runtime) / 1000 + ' seconds' || 'N/A' }}</span>
                    <span>Average run time</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between">
                    <span>{{ task.upcoming || 'N/A' }}</span>
                    <span>Next Run Schedule</span>
                </b-list-group-item>
            </b-list-group>
            <b-card-footer class="d-flex justify-content-between">
                <div class="d-flex flex-nowrap">
                    <b-button variant="primary" class="mr-2" :to="{name: 'totem.edit.task', params: {id: task.id}}">
                        Edit
                    </b-button>
                    <confirmation @true="deleteTask">
                        <template v-slot:activator="{showDialog}">
                            <b-button variant="outline-danger" @click="showDialog">Delete</b-button>
                        </template>
                    </confirmation>
                </div>
                <div>
                    <b-overlay :show="taskExecuting" spinner-small spinner-variant="primary" class="d-inline-block">
                        <b-button variant="primary" @click="runTask">Execute</b-button>
                    </b-overlay>
                </div>
            </b-card-footer>
        </b-card>
        <b-card v-if="task">
            <b-card-header>Execution results</b-card-header>
            <b-table :items="task.results" :fields="resultFields" striped>
                <template #cell(result)="data">
                    <b-button @click="openResultId = data.item.id" size="sm" variant="primary" v-if="data.item.result">
                        <feather-icon icon="EyeIcon"></feather-icon>
                    </b-button>
                </template>
            </b-table>
            <b-modal :visible="openResultId !== null" @hidden="openResultId = null" ok-only size="lg" centered
                     scrollable>
                <pre>
                    {{ _.get(_.find(task.results, {id: openResultId}), 'result') }}
                </pre>
            </b-modal>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardFooter,
    BBreadcrumb,
    BBreadcrumbItem,
    BListGroup,
    BListGroupItem,
    BButton,
    BTable,
    BModal,
    BOverlay
} from "bootstrap-vue";
import {mapActions} from "vuex";
import moment from 'moment'
import Confirmation from "@/views/admin/elements/Confirmation";

export default {
    name: "TotemTask",
    components: {
        Confirmation,
        BCard,
        BCardHeader,
        BCardFooter,
        BBreadcrumb,
        BBreadcrumbItem,
        BListGroup,
        BListGroupItem,
        BButton,
        BTable,
        BModal,
        BOverlay
    },
    data() {
        return {
            resultFields: [
                {key: 'ran_at', label: 'Executed at', formatter: v => moment(v).format('DD.MM.YYYY HH:mm'), sortable: true},
                {key: 'duration', label: 'Duration', formatter: v => Math.round(v) / 1000 + ' sec'},
                {key: 'result', label: ''}
            ],
            openResultId: null,
            task: {},
            taskExecuting: false
        }
    },
    created() {
        this.fetchTask(this.$route.params.id).then(response => {
            this.task = response.data
        })
    },
    methods: {
        ...mapActions('totem', ['fetchTask', 'destroyTask', 'activateTask', 'deactivateTask', 'executeTask']),
        deleteTask() {
            this.destroyTask(this.task.id).then(() => {
                this.$router.push({name: 'totem.dashboard'})
            })
        },
        runTask() {
            this.taskExecuting = true
            this.executeTask(this.task.id)
                .then(() => {
                    this.fetchTask(this.$route.params.id).then(response => {
                        this.task = response.data
                    })
                })
                .finally(() => {
                    this.taskExecuting = false
                })
        }
    }
}
</script>

import axios from '@axios'
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        websitesPaginated: [],
        website: {},
        xpaths: []
    },
    mutations: {
        SET_WEBSITES: (state, data) => {
            state.websitesPaginated = data;
        },
        SET_WEBSITE: (state, data) => {
            state.website = data;
        },
        SET_XPATHS: (state, data) => {
            state.xpaths = data
        },
        UPDATE_XPATH: (state, data) => {
            let itemIndex = _.findIndex(state.xpaths, {id: data.id})
            if (itemIndex === -1) {
                state.xpaths.push(data)
            } else {
                state.xpaths[itemIndex] = data
            }
        }
    },
    actions: {
        fetch(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.get('/websites', {
                    params: payload
                }).then(response => {
                    ctx.commit('SET_WEBSITES', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get(ctx, website_id) {
            return new Promise((resolve, reject) => {
                axios.get('/websites/' + website_id).then(response => {
                    ctx.commit('SET_WEBSITE', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        saveWebsite(ctx, website) {
            return new Promise((resolve, reject) => {
                axios.post('/websites/' + website.id, website)
                    .then(response => {
                        ctx.commit('SET_WEBSITE', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getXpaths(ctx, website_id) {
            return new Promise((resolve, reject) => {
                axios.get('/websites/' + website_id + '/xpaths').then(response => {
                    ctx.commit('SET_XPATHS', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        saveXpath(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/websites/' + payload.website + '/xpaths/' + payload.xpath.id, payload.xpath)
                    .then(response => {
                        resolve(response)
                        ctx.commit('UPDATE_XPATH', response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}

export default [
    {
        title: 'Websites',
        route: 'websites',
        icon: 'GlobeIcon'
    },
    {
        title: 'Categories',
        route: 'categories.mapping',
        icon: 'BoxIcon'
    },
    {
        title: 'Attributes',
        route: 'attributes',
        icon: 'TriangleIcon'
    },
    {
        title: 'Xpath reports',
        route: 'xpath.reports',
        icon: 'FileTextIcon'
    },
    {
        title: 'Totem',
        route: 'totem.dashboard',
        icon: 'CommandIcon'
    }
]

<template>
    <b-card>
        <b-breadcrumb>
            <b-breadcrumb-item>
                <router-link :to="{name: 'totem.dashboard'}">
                    Dashboard
                </router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item v-if="$route.params.id">
                <router-link :to="{name: 'totem.task', params: {id: $route.params.id}}">
                    View task
                </router-link>
            </b-breadcrumb-item>
        </b-breadcrumb>
        <b-form>
            <b-form-group label-cols-lg="4" label="Description" description="Provide descriptive name for your task">
                <b-form-input v-model="task.description"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-lg="4" label="Command" description="Select an artisan command to schedule">
                <vue-select :options="Object.values(commands)" value="name" label="name"
                            v-model="task.command" :reduce="command => command.name"></vue-select>
            </b-form-group>
            <b-form-group label-cols-lg="4" label="Parameters (optional)"
                          description="Command parameters required to run the selected command">
                <b-form-input v-model="task.parameters" placeholder="e.g. --type=all"></b-form-input>
            </b-form-group>
            <hr>
            <b-form-group label-cols-lg="4" label="Timezone"
                          description="Select a timezone for your task. App timezone is selected by default">
                <vue-select :options="Object.values(timezones)" v-model="task.timezone"></vue-select>
            </b-form-group>
            <b-form-group label-cols-lg="4" label="Type"
                          description="Choose whether to define a cron expression or to add frequencies">
                <b-form-radio-group
                    :options="[{text: 'Expression', value: 'expression'}, {text: 'Frequencies', value: 'frequency'}]"
                    v-model="task.type" stacked></b-form-radio-group>
            </b-form-group>
            <b-form-group label-cols-lg="4" label="Frequencies"
                          v-if="task.type === 'frequency'"
                          description="Add frequencies to your task. These frequencies will be converted into a cron expression while scheduling the task">
                <b-button size="sm" variant="outline-primary" @click="frequencyModal = true">Add frequency</b-button>
                <b-modal v-model="frequencyModal" title="Add frequency" no-close-on-backdrop @ok="addFrequency"
                         @cancel="newFrequency = {}">
                    <vue-select :options="frequencies" placeholder="Select type of frequency"
                                v-model="newFrequency"></vue-select>
                    <div v-if="newFrequency.parameters !== false">
                        <b-form-group :label="parameter.label" v-for="parameter in newFrequency.parameters"
                                      :key="parameter.name">
                            <b-form-input v-model="parameter.value"></b-form-input>
                        </b-form-group>
                    </div>
                </b-modal>
            </b-form-group>
            <b-form-group label-cols-lg="4" v-if="task.type === 'frequency'">
                <b-table-lite :items="task.frequencies"
                              :fields="[{key: 'label', label: 'Name'}, {key: 'parameters', label: 'Parameters'}, {key: 'action', label: ''}]"
                              small>
                    <template #cell(action)="data">
                        <b-button size="sm" variant="outline-danger" @click="removeFrequency(data.item.id)">
                            <feather-icon icon="XIcon"></feather-icon>
                        </b-button>
                    </template>
                    <template #cell(parameters)="data">
                        <div v-for="(parameter, index) in data.item.parameters" :key="index">
                            {{ parameter.name }} {{ parameter.value }}
                        </div>
                    </template>
                </b-table-lite>
            </b-form-group>
            <b-form-group label-cols-lg="4" label="CRON EXPRESSION"
                          v-if="task.type === 'expression'"
                          description="Add a cron expression for your task">
                <b-form-input v-model="task.expression"
                              placeholder="e.g * * * * * to run this task all the time"></b-form-input>
            </b-form-group>
            <hr>
            <b-form-group label-cols-lg="4" label="Email notification (optional)"
                          description="Add an email address to receive notifications when this task get executed. Leave empty if you do not wish to receive email notifications">
                <b-form-input v-model="task.notification_email_address" placeholder="e.g. john@name.tld"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-lg="4" label="SMS notification (optional)"
                          description="Add a phone number to receive SMS notifications. Leave empty if you do not wish to receive sms notifications">
                <b-form-input v-model="task.notification_phone_number" placeholder="e.g. 359232342343"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-lg="4" label="Slack notification (optional)"
                          description="Add a slack web hook url to recieve slack notifications. Leave empty if you do not wish to receive slack notifications">
                <b-form-input v-model="task.notification_slack_webhook"
                              placeholder="e.g. https://hook.slack.com/TXXXX/XXXXXXXXXX"></b-form-input>
            </b-form-group>
            <hr>
            <b-form-group label-cols-lg="4" label="MISCELLANEOUS OPTIONS">
                <template #description>
                    <p>Decide whether multiple instances of same task should overlap each other or not.</p>
                    <p>Decide whether the task should be executed while the app is in maintenance mode.</p>
                    <p>Decide whether the task should be executed on a single server.</p>
                    <p>Decide whether the task should be executed in the background.</p>
                </template>
                <b-form-checkbox v-model="task.dont_overlap" :checked-value="0" :value="1">Don't Overlap
                </b-form-checkbox>
                <b-form-checkbox v-model="task.run_in_maintenance" :checked-value="0" :value="1">Run in
                    maintenance mode
                </b-form-checkbox>
                <b-form-checkbox v-model="task.run_on_one_server" :checked-value="0" :value="1">Run on a single
                    server
                </b-form-checkbox>
                <b-form-checkbox v-model="task.run_in_background" :checked-value="0" :value="1">Run in the
                    background
                </b-form-checkbox>
            </b-form-group>
            <hr>
            <b-form-group label-cols-lg="4" label="CLEANUP OPTIONS"
                          description="Determine if an over-abundance of results will be removed after a set limit or age. Set non-zero value to enable.">
                <b-form-input v-model="task.auto_cleanup_num" placeholder="Auto Cleanup results after"></b-form-input>
                <b-form-radio-group stacked v-model="task.auto_cleanup_type"
                                    :options="[{value: 'results', text: 'Results'}, {value: 'days', text: 'Days'}]"
                                    class="mt-1"></b-form-radio-group>
            </b-form-group>
            <hr>
            <b-button variant="primary" @click="saveTask">Save</b-button>
        </b-form>
    </b-card>
</template>

<script>
import {mapActions} from "vuex";
import {
    BForm,
    BCard,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BButton,
    BTableLite,
    BModal,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem
} from "bootstrap-vue";
import VueSelect from "vue-select";
import _ from 'lodash'

export default {
    components: {
        BForm,
        BCard,
        BFormInput,
        BFormGroup,
        BFormRadioGroup,
        BButton,
        BTableLite,
        BModal,
        BFormCheckbox,
        BBreadcrumb,
        BBreadcrumbItem,
        VueSelect
    },
    name: "TotemTaskForm",
    data() {
        return {
            task: {
                frequencies: [],
                timezone: 'Europe/Sofia',
                type: 'frequency'
            },
            commands: {},
            frequencies: [],
            timezones: [],
            frequencyModal: false,
            newFrequency: {
                parameters: []
            }
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.fetchEditData()
        } else {
            this.fetchCreateData()
        }
    },
    methods: {
        ...mapActions('totem', ['fetchTask']),
        fetchCreateData() {
            this.$http.get('/totem/create').then(response => {
                this.commands = response.data.commands
                this.frequencies = response.data.frequencies
                this.timezones = response.data.timezones
            })
        },
        fetchEditData() {
            this.$http.get('/totem/edit/' + this.$route.params.id).then(response => {
                this.task = response.data.task
                if (this.task.frequencies.length) {
                    this.task.type = 'frequency'
                }
                this.commands = response.data.commands
                this.frequencies = response.data.frequencies
                this.timezones = response.data.timezones
            })
        },
        removeFrequency(frequency_id) {
            let frequencyIndex = _.findIndex(this.task.frequencies, {id: frequency_id});
            if (frequencyIndex > -1) {
                this.task.frequencies.splice(frequencyIndex, 1)
            }
        },
        addFrequency() {
            if (this.newFrequency.parameters === false) {
                this.newFrequency.parameters = []
            }
            this.newFrequency.parameters.map(parameter => {
                return {
                    name: parameter.name,
                    value: parameter.value
                }
            })
            this.task.frequencies.push(this.newFrequency)
        },
        saveTask() {
            if (this.$route.params.id) {
                this.$http.post('/totem/edit/' + this.$route.params.id, this.task).then(() => {
                    this.$router.push({name: 'totem.task', params: {id: this.$route.params.id}})
                })
            } else {
                this.$http.post('/totem/create', this.task).then(response => {
                    this.$router.push({name: 'totem.task', params: {id: response.data.id}})
                })
            }
        }
    }
}
</script>
